import React from "react"
import Button from "@material-ui/core/Button"
// import TextField from "@material-ui/core/TextField"
import TextField from "../forms/Inputs/textField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Loading from "../partials/loading"
import { Formik, Field } from "formik"

import { useStateValue } from "../../state"
import { setUser, startLoading, stopLoading } from "../../state/actions"
import User from "../../api/user"

export default function LoginModal(props) {
  const { open, onClose } = props
  const [state, dispatch] = useStateValue()
  const { isLoading } = state
  
    const handleSubmit = async values => {
      const { email, password } = values
      dispatch(startLoading())
      const response = await User.login(email, password)
      if (response instanceof Error) {
      } else {
        dispatch(setUser(response.data.user))
        onClose()
      }
      dispatch(stopLoading())
    }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <DialogTitle id="form-dialog-title">Login</DialogTitle>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <DialogContent>
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    placeholder="e.g. example@gmail.com"
                  />
                  <Field
                    component={TextField}
                    name="password"
                    label="Password"
                    type="password"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Login
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </>
      )}
    </Dialog>
  )
}
